import { useCallback, useState } from 'react';
import { Routes } from '../core/constants';
import { Avatar } from '../components/Avatar';
import logo from '../assets/img/logo_chiken.png';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import CustomPopover from '../components/Popover';
import { PoweroffOutlined,
  MenuOutlined,
  DashboardOutlined,
  FundViewOutlined,
  TeamOutlined,
  FileAddOutlined,
  MailOutlined } from '@ant-design/icons';
import { Auth } from '../core/constants/auth';
import FeedbackBugs from './Feedback/FeedbackBugs';
import FeedbackImprovement from './Feedback/FeedbackImprovement';
import FeedbackScoring from './Feedback/FeedbackScoring';
import AddTitle from './AddTitle';

const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  background: white;
  width: 100%;
  padding: 12px 32px;
  box-shadow: 0px 5px 32px -3px rgba(147, 147, 147, 0.25);
`;

const Logo = styled.a`
  max-width: 85px;
  display: block;
  img {
    width: 100%;
  }
`;

const MainMenu = styled.ul`
  list-style-type: none;
  margin: 0 32px;
  padding: 0;
  display: flex;
  li {
    font-size: 16px;
    padding: 6px;
    margin: 0 16px;
    &:hover {
      cursor: pointer;
    }
  }
`;

const MenuItem = styled.li`
  color: ${({ active }) => (active ? '#00B27B' : 'rgba(0, 0, 0, 0.85)')};
  color: ${({ inActive }) => inActive && 'rgba(0, 0, 0, 0.25)'};
`;

const MenuDropdown = styled.span`
  margin-right: 32px; 
  margin-left: auto;
  &:hover{
    cursor: pointer;
  }
`;

const AddTitleBtn = styled.button.attrs({
  type: 'button'
})`
   margin-right: 32px; 
   border: none;
   background: none;
   &:hover{
    cursor: pointer;
   }
`;

const Header = ({ user }) => {
  const { push } = useHistory();

  const logOut = useCallback(() => {
    window.gtag('event', 'login', { event_category: 'Login', event_label: 'User Logged Out', method: 'Breda Auth' });
    localStorage.removeItem('expireTime');
    localStorage.removeItem(Auth.ACCESS_TOKEN_KEY);
    localStorage.removeItem(Auth.REFRESH_TOKEN_KEY);
    push('/');
  }, []);

  const path = window.location.pathname;
  const [feedbackType, setFeedbackType] = useState('');
  const [addTitleOpen, setAddTitleOpen] = useState(false);
  const onClose = () => setFeedbackType('');

  const hasAdminRole = () => (
    user.role === 'Super Admin' || user.role === 'Acquisitions Lead'
  );

  return (<>
    <WrapperHeader>
      <Logo href='/'>
        <img src={logo} alt='logo' />
      </Logo>

      <MainMenu>
        <Link to={Routes.bookstat}>
          <MenuItem active={path.includes(Routes.bookstat)}>Bookstat</MenuItem>
        </Link>
        <Link to={Routes.royalRoad}>
          <MenuItem active={path.includes(Routes.royalRoad)}>Royal Road</MenuItem>
        </Link>
        <Link to={Routes.dailyRank}>
          <MenuItem active={path.includes(Routes.dailyRank)}>Daily Rank</MenuItem>
        </Link>
      </MainMenu>

      <CustomPopover
        content={
          <ul>
            <li style={{ display: path.includes(Routes.bookstat) ? 'block' : 'none' }}>
              <AddTitleBtn onClick={() => {
                window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Open Add Title' });
                setAddTitleOpen(true);
              }}>
                <FileAddOutlined /> Add Title
              </AddTitleBtn>
            </li>
            <li>
              <Link to={Routes.dashboard}>
                <MenuItem
                  active={false}
                  onClick={() => {
                    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Open Dashboard' });
                  }}
                >
                  <DashboardOutlined /> Dashboard
                </MenuItem>
              </Link>
            </li>
            <li style={{ display: hasAdminRole() ? 'block' : 'none' }}>
              <Link to={Routes.usersAdminDashboard}>
                <MenuItem
                  active={false}
                  onClick={() => {
                    window.gtag('event', 'click', {
                      event_category: 'Interaction',
                      event_label: 'Open Admin Dashboard'
                    });
                  }}
                >
                  <FundViewOutlined /> Usage Stats
                </MenuItem>
              </Link>
            </li>
            <li>
              <CustomPopover
                content={<ul
                  onClick={() => {
                    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Open Feedback' });
                  }
                  }
                >
                  <li><button className='basic-btn' onClick={() => setFeedbackType('Error/Bug')}>Error/Bug</button></li>
                  <li>
                    <button className='basic-btn' onClick={() => setFeedbackType('Improvement')}
                    >
                      Improvement
                    </button>
                  </li>
                  <li>
                    <button className='basic-btn' onClick={() => setFeedbackType('Scoring')}
                    >
                        Brenda Scoring
                    </button>
                  </li>
                </ul>}
                trigger='hover'
                placement='left'
              >
                <div style={{ marginLeft: '5px' }}>
                  <MailOutlined /> Feedback
                </div>
              </CustomPopover>
            </li>
            <li>
              <Link to={Routes.users}>
                <MenuItem
                  active={false}
                  onClick={() => {
                    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Open Manage Users' });
                  }}
                >
                  <TeamOutlined /> Manage Users
                </MenuItem>
              </Link>
            </li>
          </ul>}
        trigger='hover'
        placement='bottomRight'
      >
        <MenuDropdown>
          <button
            className='dropdown-hamburger-menu basic-btn'
            onMouseEnter={() => {
              window.gtag('event', 'hover', { event_category: 'Interaction', event_label: 'Open Hamburger Menu' });
            }
            }
          >
            <MenuOutlined/>
          </button>
        </MenuDropdown>
      </CustomPopover>

      <CustomPopover
        content={
          <ul>
            <li
              style={{ display: 'flex', alignItems: 'center' }}
            ><button className='basic-btn' onClick={logOut}>
                <PoweroffOutlined style={{ color: '#FB757B' }} />
                Log Out
              </button>
            </li>
          </ul>
        }
        trigger='click'
        placement='bottomRight'
      >
        <Avatar src={user?.picture} user={user?.first_name?.[0]} />
      </CustomPopover>
    </WrapperHeader>

    {feedbackType === 'Error/Bug' &&
      <FeedbackBugs onClose={onClose} open={feedbackType === 'Error/Bug'} user={user}/>}
    {feedbackType === 'Improvement' &&
      <FeedbackImprovement onClose={onClose} open={feedbackType === 'Improvement'} user={user}/>}
    {feedbackType === 'Scoring' && <FeedbackScoring onClose={onClose} open={feedbackType === 'Scoring'} />}
    {addTitleOpen && <AddTitle onClose={() => setAddTitleOpen(false)} open={addTitleOpen} />}
  </>
  );
};

export default Header;