/* eslint-disable react-hooks/rules-of-hooks */
import EditableCell from '../../../composed-components/EditableCell';
import Endpoints, { ROYAL_ROAD_TITLE, TWITTER, AMAZON, ROYAL_ROAD_AUTHOR } from '../../../core/constants/endpoints';
import { BoxedSetTag, Hint, LateBloomerTag, RisingStar } from '../../../components/FormItem';
import AlsoBought from '../../../composed-components/AlsoBought';
import { useActions } from '../../../context/Actions.context';
import RankingsTableModal from '../../../composed-components/RankingDetails';
import RankingsGraphModal from '../../../composed-components/RankingsGraph/RankingsGraphModal';
import formatDate from '../utils/dateFormatter';
import { hasValue } from '../utils/stringUtils';

const getSource = () => {
  const { source: sourcePath } = useActions();

  return sourcePath['source'];
};

const getAuthorStyle = (book) => {
  let style = { };

  switch (true) {
  case Boolean(book['CRM Author Id']):
    style = { background: 'rgb(158 255 201 / 38%)' };
    break;
  case Boolean(book['Podium Author Id']):
    style = { background: '#fff59d5c' };
    break;
  case Boolean(book.is_podium_author):
    style = { background: 'rgba(255, 245, 157, 0.42)' };
    break;
  default:
    break;
  }
  return style;
};

const renderListOfLinks = (links: string): { children: JSX.Element[] } => {
  if (!hasValue(links)) {
    return { children: [<></>] };
  }

  const urls = links.split(',');
  const regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;

  return {
    children: urls.filter((item, index) => urls.indexOf(item) === index)
      .map((link) => {
        if (regex.exec(link)) {
          return (
            <button className='table-cell-link basic-btn' key={link} onClick={e => e.stopPropagation()} >
              <a className='table-cell-asin'
                href={`${link}`}
                rel='noreferrer'
                target='_blank' >
                {link}
              </a>
            </button>);
        } else {
          return (<></>);
        }
      })
  };
};

const getAllColumns = sorting => ({
  'Added to Watchlist': {
    title: () => sorting('Added to Watchlist'),
    dataIndex: 'Added to Watchlist',
    key: 'Added to Watchlist',
    className: 'show',
    width: 130,
    render: date => {
      const splitedDate = date?.split(' ')?.[0];

      return splitedDate;
    }
  },

  'Sent to CRM on date': {
    title: () => sorting('Sent to CRM on date', 'Sent to HubSpot'),
    dataIndex: 'Sent to CRM on date',
    key: 'Sent to CRM on date',
    className: 'show',
    width: 130,
    render: date => {
      const splitedDate = date?.split(' ')?.[0];

      return splitedDate;
    }
  },

  Added: {
    title: () => sorting('Added'),
    dataIndex: 'Added',
    key: 'Added',
    className: 'show',
    width: 120,
    render: text => (text ? text.split(' ')[0] : '')
  },

  'Days Tracked': {
    title: () => sorting('Days Tracked'),
    dataIndex: 'Days Tracked',
    key: 'Days Tracked',
    className: 'show',
    width: 100
  },

  'Audible Product ID': {
    title: 'Audible Product ID',
    dataIndex: 'Podium Id',
    key: 'Audible Product ID',
    className: 'show',
    width: 200
  },

  Title: {
    title: 'Title',
    dataIndex: 'Title',
    key: 'Title',
    className: 'show',
    width: 220,
    render: (Title: string, _book) => {
      if (Title && _book['Fiction Id']) {
        return {
          children: <button style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          className='basic-btn'
          onClick={(e) => e.stopPropagation()}><a
              className='table-cell-asin'
              href={`${Endpoints[ROYAL_ROAD_TITLE]}${_book['Fiction Id']}`}
              rel='noreferrer'
              target='_blank'
            >
              {Title}
            </a>
            {_book.is_risingstar ?
              (<RisingStar hint='This title has been a Rising Star.' color={'#F6BE00'}/>) : null}
          </button>
        };
      } else {
        return {
          children: <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            {Title || ''}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {_book.late_bloomer ?
                (<LateBloomerTag hint='Late Bloomer' color={'red'}/>) : null}
              {_book.series_has_audio ?
                (<Hint hint='A title in this series has audio' color={'#00B27B'}/>) : null}
              {_book.is_boxset ?
                (<BoxedSetTag hint='This title is a boxed set'/>) : null}
            </div>
          </div>
        };
      }
    }
  },

  Author: {
    title: () => sorting('Author'),
    dataIndex: 'Author',
    key: 'Author',
    className: 'show',
    fixed: getSource().includes('daily-rank') ? 'left' : '',
    width: 120,
    render: (text, book) => {
      const style = getAuthorStyle(book);

      if (book.ASIN) {
        return {
          props: {
            style
          },
          children: <div>{text || ''}</div>
        };
      }

      if (book['Author Id']) {
        return {
          props: {
            style
          },
          children: <button className='basic-btn' onClick={(e) => e.stopPropagation()}>
            <a
              className='table-cell-asin'
              href={`${Endpoints[ROYAL_ROAD_AUTHOR]}${book['Author Id']}`}
              rel='noreferrer'
              target='_blank'
            >
              {text}
            </a></button>
        };
      }

      return text;
    }
  },

  Series: {
    title: getSource().includes('daily-rank') ? 'Series Title - Title' : () => sorting('Series'),
    dataIndex: 'Series',
    key: 'Series',
    className: 'show',
    width: 180,
    fixed: getSource().includes('daily-rank') ? 'left' : '',
    render: (series, _book) => {
      if (getSource().includes('daily-rank')) {
        if (series && _book.Title) {
          return (<span>{series} - {_book.Title}</span>);
        } else {
          return (<span>{_book.Title}</span>);
        }
      } else {
        return (<span>{series}</span>);
      }
    }
  },

  ASIN: {
    title: 'ASIN',
    dataIndex: 'ASIN',
    key: 'ASIN',
    className: 'show',
    width: 90,
    fixed: getSource().includes('daily-rank') ? 'left' : '',
    render: (asin: string) => {
      if (asin) {
        return {
          children: <button className='basic-btn' onClick={(e) => e.stopPropagation()}><a
            className='table-cell-asin'
            href={`${Endpoints[AMAZON]}${asin}`}
            rel='noreferrer'
            target='_blank'
          >
            {asin}
          </a></button>
        };
      }
    }
  },

  Confidence: {
    title: () => sorting('Confidence'),
    dataIndex: 'Confidence',
    key: 'Confidence',
    className: 'show',
    width: 150,
    render: text => (text || text === 0 ? <span>{`${text}%`}</span> : <></>)
  },

  'Average Confidence': {
    title: () => sorting('Average Confidence'),
    dataIndex: 'Average Confidence',
    key: 'Average Confidence',
    className: 'show',
    width: 150,
    render: text => (text || text === 0 ? <span>{`${text}%`}</span> : <></>)
  },

  'Max Confidence': {
    title: () => sorting('Max Confidence'),
    dataIndex: 'Max Confidence',
    key: 'Max Confidence',
    className: 'show',
    width: 150,
    render: text => (text || text === 0 ? <span>{`${text}%`}</span> : <></>)
  },

  'Num. of Pages': {
    title: () => sorting('Num. of Pages'),
    dataIndex: 'Num. of Pages',
    key: 'Num. of Pages',
    className: 'show',
    width: 150
  },
  'Avg. Also Bought Bestseller Rank': {
    title: () => sorting('Avg. Also Bought Bestseller Rank'),
    dataIndex: 'Avg. Also Bought Bestseller Rank',
    key: 'Avg. Also Bought Bestseller Rank',
    className: 'show',
    width: 130,
    render: (rank: string) => ({
      children: <div>{rank || '-' }</div>
    })
  },

  ISBN: {
    title: 'ISBN',
    dataIndex: 'ISBN',
    key: 'ISBN',
    className: 'show',
    width: 160
  },

  Agent: {
    title: () => sorting('Agent'),
    dataIndex: 'Agent',
    key: 'Agent',
    className: 'show',
    width: 160
  },
  Agency: {
    title: () => sorting('Agency'),
    dataIndex: 'Agency',
    key: 'Agency',
    className: 'show',
    width: 160
  },
  'Publisher Type': {
    title: 'Publisher Type',
    dataIndex: 'Publisher Type',
    key: 'Publisher Type',
    className: 'show',
    width: 230
  },

  Genre: {
    title: 'Genre',
    dataIndex: 'Genre',
    key: 'Genre',
    className: 'show',
    width: 200
  },

  'Date Pub': {
    title: 'Date Pub',
    dataIndex: 'Date Pub',
    key: 'Date Pub',
    className: 'show wrap-cell',
    width: 120,
    render: text => text?.split(' ')[0]
  },

  'Podium Hit Count': {
    title: () => sorting('Podium Hit Count'),
    dataIndex: 'Podium Hit Count',
    key: 'Podium Hit Count',
    className: 'show',
    width: 130,
    render: (text, book) => {
      const result = <AlsoBought podiumHitCount={text === null ? -1 : text} asin={book.key}/>;

      return {
        children: result
      };
    }
  },

  'Days On Sale': {
    title: () => sorting('Days On Sale'),
    dataIndex: 'Days On Sale',
    key: 'Days On Sale',
    className: 'show',
    width: 100
  },

  'Amzn Rating': {
    title: () => sorting('Amzn Rating'),
    dataIndex: 'Amzn Rating',
    key: 'Amzn Rating',
    className: 'show',
    width: 100,
    render: text => {
      if (text === null) {
        return <span>No rating</span>;
      }

      return <span>{text}</span>;
    }
  },

  'Amzn Review': {
    title: () => sorting('Amzn Review'),
    dataIndex: 'Amzn Review',
    key: 'Amzn Review',
    className: 'show',
    width: 100
  },

  'eBooks Sold': {
    title: () => sorting('eBooks Sold'),
    dataIndex: 'eBooks Sold',
    key: 'eBooks Sold',
    className: 'show',
    width: 100
  },

  'Projected Sales': {
    title: () => sorting('Projected Sales'),
    dataIndex: 'Projected Sales',
    key: 'Projected Sales',
    className: 'show',
    width: 140
  },

  'Average Projected Sales': {
    title: () => sorting('Average Projected Sales'),
    dataIndex: 'Average Projected Sales',
    key: 'Average Projected Sales',
    className: 'show',
    width: 140
  },

  'Max Projected Sales': {
    title: () => sorting('Max Projected Sales'),
    dataIndex: 'Max Projected Sales',
    key: 'Max Projected Sales',
    className: 'show',
    width: 140
  },

  Assignee: {
    title: 'Assignee',
    dataIndex: 'Assignee',
    className: 'show',
    key: 'Assignee',
    width: 100
  },

  Tier: {
    title: 'Tier',
    dataIndex: 'Tier',
    className: 'show',
    key: 'Tier',
    width: 90
  },

  Priority: {
    title: 'Priority',
    dataIndex: 'Priority',
    className: 'show',
    key: 'Priority',
    width: 100
  },

  'Podium Parent Genre': {
    title: 'Podium Parent Genre',
    dataIndex: 'Podium Parent Genre',
    className: 'show',
    key: 'Podium Parent Genre',
    width: 100
  },

  'First 12 months sales (units)': {
    title: 'First 12 months sales (units)',
    dataIndex: 'First Year Audiobooks Sold',
    className: 'show',
    key: 'First 12 months sales (units)',
    width: 100,
    render: text => {
      const data = text?.split(',');

      return data ? data[data?.length - 1] : '';
    }
  },

  'Trailing 12 months sales (units)': {
    title: 'Trailing 12 months sales (units)',
    dataIndex: 'Trailing Year Audiobooks Sold',
    className: 'show',
    key: 'Trailing 12 months sales (units)',
    width: 100,
    render: text => {
      const data = text?.split(',');

      return data ? data[data?.length - 1] : '';
    }
  },

  'Lifetime sales (units)': {
    title: 'Lifetime sales (units)',
    dataIndex: 'Lifetime Audiobooks Sold',
    className: 'show',
    key: 'Lifetime sales (units)',
    width: 100,
    render: text => {
      const data = text?.split(',');

      return data ? data[data?.length - 1] : '';
    }
  },

  'Lifetime sales (dollars)': {
    title: 'Lifetime sales (dollars)',
    dataIndex: 'Lifetime Sales',
    className: 'show',
    key: 'Lifetime sales (dollars)',
    width: 100,
    render: text => {
      const data = text?.split(',');

      return data ? data[data?.length - 1] : '';
    }
  },

  Price: {
    title: () => sorting('Price'),
    dataIndex: 'Price',
    key: 'Price',
    className: 'show',
    width: 100,
    render: text => <span>${text ?? 0}</span>
  },

  'Kindle Sales Rank': {
    title: 'Kindle Sales Rank',
    dataIndex: 'Kindle Sales Rank',
    className: 'show',
    key: 'Kindle Sales Rank',
    width: 100
  },

  'Kindle Unlimited': {
    title: 'Kindle Unlimited',
    dataIndex: 'Kindle Unlimited',
    className: 'show',
    key: 'Kindle Unlimited',
    width: 100,
    render: text => (text ? 'Yes' : 'No')
  },

  Tags: {
    title: 'Tags',
    dataIndex: 'Secondary Tags',
    key: 'Secondary Tags',
    className: 'show',
    width: 180,
    render: (text, book) => <EditableCell book={book} text={text} />
  },

  Patreon: {
    title: 'Patreon',
    dataIndex: 'Patreon',
    key: 'Patreon',
    className: 'show',
    width: 130,
    render: (patreon: string) => renderListOfLinks(patreon)
  },

  'External Links': {
    title: 'External Links',
    dataIndex: 'External Links',
    key: 'External Links',
    className: 'show',
    width: 130,
    render: (links: string) => renderListOfLinks(links)
  },

  'First Published Date': {
    title: () => sorting('First Published Date'),
    dataIndex: 'First Published Date',
    key: 'First Published Date',
    className: 'show wrap-cell',
    width: 110,
    render: text => formatDate(text)
  },

  'Overall Score': {
    title: () => sorting('Overall Score'),
    dataIndex: 'Overall Score',
    key: 'Overall Score',
    className: 'show',
    width: 100,
    render: text => <span>{text ?? 0}</span>
  },

  'Grammar Score': {
    title: () => sorting('Grammar Score'),
    dataIndex: 'Grammar Score',
    key: 'Grammar Score',
    className: 'show',
    width: 100,
    render: text => <span>{text ?? 0}</span>
  },

  Views: {
    title: () => sorting('Views'),
    dataIndex: 'Views',
    key: 'Views',
    className: 'show',
    width: 100,
    render: text => <span>{text ?? 0}</span>
  },

  Followers: {
    title: () => sorting('Followers'),
    dataIndex: 'Followers',
    key: 'Followers',
    className: 'show',
    width: 90,
    render: text => <span>{text ?? 0}</span>
  },

  Favorites: {
    title: () => sorting('Favorites'),
    dataIndex: 'Favorites',
    key: 'Favorites',
    className: 'show',
    width: 90,
    render: text => <span>{text ?? 0}</span>
  },

  Ratings: {
    title: () => sorting('Ratings'),
    dataIndex: 'Ratings',
    key: 'Ratings',
    className: 'show',
    width: 90,
    render: text => <span>{text ?? 0}</span>
  },

  'Author Ratings': {
    title: () => sorting('Author Ratings'),
    dataIndex: 'Author Ratings',
    key: 'Author Ratings',
    className: 'show',
    width: 90,
    render: text => <span>{text ?? 0}</span>
  },

  Pages: {
    title: () => sorting('Pages'),
    dataIndex: 'Pages',
    key: 'Pages',
    className: 'show',
    width: 90,
    render: text => <span>{text ?? 0}</span>
  },

  Type: {
    title: 'Type',
    dataIndex: 'Type',
    key: 'Type',
    className: 'show',
    width: 230
  },

  Warnings: {
    title: 'Warnings',
    dataIndex: 'Warnings',
    key: 'Warnings',
    className: 'show',
    width: 180
  },

  Status: {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
    className: 'show',
    width: 230
  },

  Fictions: {
    title: () => sorting('Fictions'),
    dataIndex: 'Fictions',
    key: 'Fictions',
    className: 'show',
    width: 130,
    render: text => <span>{text ?? 0}</span>
  },

  Words: {
    title: () => sorting('Words'),
    dataIndex: 'Words',
    key: 'Words',
    className: 'show',
    width: 90,
    render: text => <span>{text ?? 0}</span>
  },

  Reviews: {
    title: () => sorting('Reviews'),
    dataIndex: 'Reviews',
    key: 'Reviews',
    className: 'show',
    width: 100,
    render: text => <span>{text ?? 0}</span>
  },

  'Reputation Level': {
    title: () => sorting('Reputation Level'),
    dataIndex: 'Reputation Level',
    key: 'Reputation Level',
    className: 'show',
    width: 230,
    render: text => <span>{text ?? ''}</span>
  },

  Achievements: {
    title: 'Achievements',
    dataIndex: 'Achievements',
    key: 'Achievements',
    className: 'show',
    width: 100,
    render: text => <span>{text ?? ''}</span>
  },

  Twitter: {
    title: 'Twitter',
    dataIndex: 'Twitter',
    key: 'Twitter',
    className: 'show',
    width: 130,
    render: (Twitter) => {
      if (Twitter) {
        return {
          children: <button onClick={(e) => e.stopPropagation()}><a
            className='table-cell-asin'
            href={`${Endpoints[TWITTER]}${Twitter}`}
            rel='noreferrer'
            target='_blank'
          >
            {Twitter}
          </a></button>
        };
      }
    }
  },

  'Release Date': {
    title: () => sorting('Release Date'),
    dataIndex: 'Release Date',
    key: 'Release Date',
    className: 'show',
    fixed: 'left',
    width: 110,
    render: text => text?.split(' ')[0]
  },

  'Highest Rank': {
    title: () => sorting('Highest Rank'),
    dataIndex: 'Highest Rank',
    key: 'Highest Rank',
    className: 'show',
    fixed: 'left',
    width: 100,
    render: rank => <span>{rank ?? ''}</span>
  },

  'Highest Rank Date': {
    title: () => sorting('Highest Rank Date'),
    dataIndex: 'Highest Rank Date',
    key: 'Highest Rank Date',
    className: 'show',
    fixed: 'left',
    width: 110,
    render: date => <span>{date ?? ''}</span>
  },

  'More': {
    title: 'More',
    dataIndex: 'More',
    key: 'More',
    className: 'show td-actions',
    fixed: 'left',
    width: 50,
    render: (_, book) => (
      <button className='actions basic-btn' onClick={(e) => e.stopPropagation()}>
        <RankingsTableModal asin={book.ASIN}/>
        <RankingsGraphModal asin={book.ASIN}/>
      </button>
    )
  },

  'Narrator': {
    title: 'Narrator',
    dataIndex: 'Narrator',
    key: 'Narrator',
    className: 'show',
    width: 90,
    render: text => <span>{text ?? ''}</span>
  },

  'Book #': {
    title: 'Book #',
    dataIndex: 'Book #',
    key: 'Book #',
    className: 'show',
    width: 60,
    render: text => <span>{text ?? ''}</span>
  },

  'Kindle Unlimited?': {
    title: 'Kindle Unlimited?',
    dataIndex: 'Kindle Unlimited?',
    key: 'Kindle Unlimited?',
    className: 'show',
    width: 90,
    render: text => <span>{text ?? ''}</span>
  },

  'Last Updated': {
    title: () => sorting('Last Updated'),
    dataIndex: 'Last Updated',
    key: 'Last Updated',
    className: 'show',
    width: 110
  },

  'Discord': {
    title: 'Discord',
    dataIndex: 'Discord',
    key: 'Discord',
    className: 'show',
    width: 130,
    render: (discord: string) => renderListOfLinks(discord)
  }
});

export default getAllColumns;