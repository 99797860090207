import React, { useState, useCallback, useEffect } from 'react';
import {
  SettingFilled,
  FileExcelOutlined,
  FilePdfOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import './styles.css';
import { Col, Row } from 'antd';
import { Select } from '../../components/Select';
import { COLUMNSHEADING, FONTSIZE } from '../../section/Common/constants';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Checkbox, CheckBoxGroup } from '../../components/Checkbox';
import { TColumns } from '../../section/Common/types/types';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import CustomPopover from '../../components/Popover';
import ExportData from '../ExportData/ExportData';
import generateXLS from '../../section/Common/utils/generateXLS';
import generatePDF from '../../section/Common/utils/generatePDF';
import toggleColor from '../utils/toggleColor';
import DeleteTitles from '../Actions/Delete/DeleteTitles';
import { useGenreController } from '../../context/GenreController.context';
import { ACTIONS_GENRE } from '../../core/constants/global-constants';
import { useUserPermit } from '../../context/User.context';
import { useActions } from '../../context/Actions.context';
import { SOURCE } from '../../core/constants/endpoints';

interface IProps {
  setColumns: Function;
  thead: TColumns[];
  changeFontSize: Function;
  deleteItems?: string[];
  deleteBooks?: Function;
  id: string;
  refetchTable: Function;
}

const Settings: React.FC<IProps> = ({
  thead,
  id,
  changeFontSize,
  setColumns,
  deleteItems = [],
  deleteBooks
}) => {
  const { source: sourcePath } = useActions();
  const [isShowVisibility, setIsShowVisibility] = useState<boolean>(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState<boolean>(false);
  const toggleModal = useCallback(
    () => setIsModalDeleteVisible(state => !state),
    []
  );
  const [checkedTitles, setCheckedTitles] = useState<CheckboxValueType[]>([]);
  const { edit_titles } = useUserPermit();

  const updateVisibleColumnsList = () => {
    const visibleColumns = thead.filter(
      column => checkedTitles.indexOf(column.key) === -1
    );

    setColumns(visibleColumns);
  };

  const handleOk = () => {
    setIsShowVisibility(false);
    updateVisibleColumnsList();
  };

  const hideAll = COLUMNSHEADING[id].filter(
    title => title !== 'Title' && title !== 'Author' && title !== 'Tags'
  );

  useEffect(() => {
    if (sourcePath['source'].includes(SOURCE['daily-rank'])) {
      updateVisibleColumnsList();
    }
  }, [thead.length]);

  const handleProvinceChange = value => {
    changeFontSize(value);
  };

  let params = '';

  for (const asin of deleteItems) {
    params += `&amazonasin=${asin}`;
  }

  const query = params.replace('&amazonasin=', '');

  const onDeleteOptions = { ASIN: query };

  const { handleGenreAction } = useGenreController();

  const settingsContent = (
    <ul className='settings-menu'>
      <li
        className='settings-menu-item'
      >
        <button className='basic-btn' onClick={() => setIsShowVisibility(true)}
        >
            Hide/Unhide columns
        </button>
      </li>
      <li className='settings-menu-item'>
        Font size:
        <Select
          className='settings-menu-item-font-size'
          defaultValue={FONTSIZE[3].value}
          style={{ width: 120 }}
          onChange={handleProvinceChange}
          options={FONTSIZE}
        />
      </li>
      {deleteItems && deleteBooks && deleteItems.length !== 0 ? (
        <li className='settings-menu-item basic-btn' >
          <button onClick={toggleModal}
          >
            Delete Titles
          </button>
        </li>
      ) : (
        <li className='settings-menu-item disable-menu-item'>Delete Titles</li>
      )}
      <li>
        <ExportData
          generateFile={generateXLS}
          typeFile={'xlsx'}
          text={'Export Excel'}
          endpoint={id}
          checkedTitles={checkedTitles}
        >
          <FileExcelOutlined className='file-excel' />
        </ExportData>
      </li>
      <li>
        <ExportData
          generateFile={generatePDF}
          typeFile={'pdf'}
          text={'Export PDF'}
          endpoint={id}
          checkedTitles={checkedTitles}
        >
          <FilePdfOutlined className='file-pdf' />
        </ExportData>
      </li>
      <li>
        <ExportData
          generateFile={generateXLS}
          typeFile={'csv'}
          text={'Export CSV'}
          endpoint={id}
          checkedTitles={checkedTitles}
        >
          <FileTextOutlined className='file-csv' />
        </ExportData>
      </li>

      {edit_titles && window.location.pathname.includes(SOURCE.bookstat) &&
        <>
          <li >
            <button
              className='btn-genre'
              onClick={() => {
                window.gtag('event', 'click', {
                  event_category: 'Interaction',
                  event_label: ACTIONS_GENRE.create,
                  content_type: 'genre'
                });
                handleGenreAction(ACTIONS_GENRE.create);
              }}
            > Create custom genre
            </button>

          </li>
          <li>
            <button
              className='btn-genre'
              onClick={() => {
                window.gtag('event', 'click', {
                  event_category: 'Interaction',
                  event_label: ACTIONS_GENRE.edit,
                  content_type: 'genre'
                });
                handleGenreAction(ACTIONS_GENRE.edit);
              }}
            > Edit custom genre
            </button>
          </li>
          <li>
            <button
              className='btn-genre'
              onClick={() => {
                window.gtag('event', 'click', {
                  event_category: 'Interaction',
                  event_label: ACTIONS_GENRE.delete,
                  content_type: 'genre'
                });
                handleGenreAction(ACTIONS_GENRE.delete);
              }}
            > Delete custom genre
            </button>
          </li>
        </>}
    </ul>
  );

  const removeDelete = () => {
    if (deleteBooks) {
      deleteBooks([]);
    }

    if (deleteItems?.length) {
      for (const el in deleteItems) {
        toggleColor(el);
      }
    }
  };

  return (
    <>
      {isShowVisibility && <Modal
        okText='OK'
        title='Hide/Unhide columns'
        visible={isShowVisibility}
        onOk={handleOk}
        onCancel={() => setIsShowVisibility(false)}
      >
        <CheckBoxGroup
          value={checkedTitles}
          onChange={values => setCheckedTitles(values)}
        >
          <Row>
            {COLUMNSHEADING[id].map((columnName: string) => {
              if (
                columnName === 'Title' ||
                columnName === 'Author' ||
                columnName === 'Tags'
              ) {
                return '';
              }

              const isChecked = checkedTitles.includes(columnName);

              return (
                <Col span={10} key={columnName}>
                  <Checkbox checked={isChecked} value={columnName}>
                    {columnName}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
          <Button
            className='unhide-button'
            onClick={() => setCheckedTitles([...hideAll])}
          >
            Hide All
          </Button>
          <Button className='unhide-button' onClick={() => setCheckedTitles([])}>
            Unhide All
          </Button>
        </CheckBoxGroup>
      </Modal>}

      {isModalDeleteVisible && (
        <DeleteTitles
          book={onDeleteOptions}
          id={id}
          removeDelete={removeDelete}
          modal={isModalDeleteVisible}
          closeModal={toggleModal}
        />
      )}

      <button
        className='settings basic-btn'
        onMouseEnter={() => {
          window.gtag('event', 'hover', { event_category: 'Interaction', event_label: 'Open Settings' });
        }}
      >
        <CustomPopover
          content={settingsContent}
          title='Settings'
          trigger='hover'
          placement='bottomRight'
        >
          <SettingFilled />
        </CustomPopover>
      </button>
    </>
  );
};

export default Settings;