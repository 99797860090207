import React from 'react';
import { ReactComponent as SortIcon } from '../../assets/sort-solid.svg';
import cn from 'classnames';
import { useSort } from '../../context/Sort.context';
import { Popover } from 'antd';
import { COLUMNINFO } from '../../section/Common/constants';

interface IProps {
  title: string;
  changePage?: Function;
  id: string;
}

const getClassNamesFor = (name, sort, id) => {
  if (sort[id]?.sortBy === name) {
    if (sort[id]?.sortType === 'ascending') {
      return 'sort-ascending';
    } else if (sort[id]?.sortType === 'descending') {
      return 'sort-descending';
    }
  }
};

const getColumnTitleWithInfo = (title: string) => (
  COLUMNINFO[title] ? (<Popover
    content={
      <div className='column-popover-info'>{COLUMNINFO[title]}</div>}
  >
    <div>{title}</div>
  </Popover>) : title
);

const Sorting: React.FC<IProps> = ({ title, id, changePage }) => {
  const { sort, setSort } = useSort();

  const onSort = () => {
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Sort Items' });
    let sortBy = '';
    let sortType = '';

    if (sort[id]?.sortBy === title) {
      sortBy = title;

      if (sort[id]?.sortType === 'ascending') {
        sortType = 'descending';
      } else {
        sortType = 'ascending';
      }
    } else {
      sortBy = title;
      sortType = 'ascending';
    }

    setSort({ ...sort, [id]: { sortBy, sortType } });

    if (changePage) {
      changePage(1);
    }
  };

  return (
    <button className='sort' onClick={onSort}>
      {getColumnTitleWithInfo(title)}
      <SortIcon
        style={{ minWidth: 10, height: 16, margin: '0 5px' }}
        className={cn('sorting-icon', getClassNamesFor(title, sort, id))}
      />
    </button>
  );
};

export default React.memo(Sorting);