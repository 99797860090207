import React, { useState } from 'react';
import styled from 'styled-components';
import SaveSearchName from './SaveSearchName';
import { useUserPermit } from '../../context/User.context';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import getParsedData from '../../section/Common/utils/getParsedData';
import { isEqualWith, isEqual } from 'lodash';
import { defaultSort } from '../../context/Sort.context';
import { Popover } from 'antd';
import { ExcludeParamKeys } from '../../section/Common/constants';

type SaveSearchButtonProps = {
  source: string;
  tab: string;
  filters: object;
  sort: object;
};

const StyledSave = styled.button`
  border: none;
  outline: none;
  background: none;
  color: #9c9a9a;
  font-weight: 600;
  margin: 0;
  svg {
    width: 26px;
    height: 26px;
  }
  &:hover { color: #00b27b; }
`;

const compareFunction = (a: string, b: string) =>
  a.toLowerCase().localeCompare(b.toLowerCase());

const caseInsensitiveComparator = (valueA, valueB) => {
  if (Array.isArray(valueA) && Array.isArray(valueB)) {
    const sortedA = valueA.slice().sort(compareFunction);
    const sortedB = valueB.slice().sort(compareFunction);

    return sortedA.length === sortedB.length && sortedA.every(
      (item, index) => item.toLowerCase() === sortedB[index].toLowerCase());
  }

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return valueA.toLowerCase() === valueB.toLowerCase();
  }

  return undefined;
};

const checkDefaultFilters = (search_query: string, tab: string) => {
  const parsedQuery = getParsedData(search_query, tab);
  const isDefaultSort = isEqual(parsedQuery.sort, defaultSort[tab]);
  const isDefaultOption = parsedQuery.filters['option'] ?
    (Object.keys(parsedQuery.filters).length === 1 &&
    parsedQuery.filters['option'] === 'All Titles') :
    Object.keys(parsedQuery.filters).length === 0;

  return isDefaultSort && isDefaultOption;
};

const SaveSearchIcon = ({ searchData, payload, onSave }) => {
  const isDefaultFilters = checkDefaultFilters(payload.search_query, payload.tab);
  const isPayloadMatch = searchData?.some(item => {
    if (item['tab'] === payload.tab && item['source'] === payload.source) {
      const parsedQuery = getParsedData(item['search_query'], payload.tab);
      const parsedPayloadQuery = getParsedData(payload.search_query, payload.tab);

      return isEqualWith(parsedQuery, parsedPayloadQuery, caseInsensitiveComparator);
    }

    return false;
  });

  const renderPopover = () => {
    if (isPayloadMatch) {
      return (
        <Popover
          content={<div className='popover-content'>Saved</div>}
          trigger='hover'
        >
          <StarFilled style={{ color: '#F6BE00', cursor: 'default' }} />
        </Popover>
      );
    }

    if (isDefaultFilters) {
      return (
        <Popover
          content={<div className='popover-content'>Select a filter to save</div>}
          trigger='hover'
        >
          <StarOutlined style={{ color: '#D9D9D9', cursor: 'default' }} />
        </Popover>
      );
    }

    return (
      <StarOutlined onClick={onSave} style={{ cursor: 'pointer' }} />
    );
  };

  return (
    <>
      {renderPopover()}
    </>
  );
};

const SaveSearchButton: React.FC<SaveSearchButtonProps> = ({ tab, filters, sort, source }) => {
  const { id, searchData, updateSearchData } = useUserPermit();
  const [openSaveSearchName, setOpenSaveSearchName] = useState(false);

  const generateSearchQuery = () => {
    const queryParts: string[] = [];

    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (!value || ExcludeParamKeys.includes(key)) {
          return;
        }

        if (Array.isArray(value)) {
          value.forEach(v => queryParts.push(`${key}=${encodeURIComponent(v)}`));
        } else if (value) {
          queryParts.push(`${key}=${encodeURIComponent(value)}`);
        }
      });
    }

    if (sort) {
      Object.entries(sort).forEach(([key, value]) => {
        if (value) {
          queryParts.push(`${key}=${encodeURIComponent(value)}`);
        }
      });
    }

    queryParts.sort(compareFunction);

    return queryParts.length > 0 ? `${queryParts.join('&')}` : '';
  };

  const payload = {
    user_id: id,
    source,
    tab,
    search_query: generateSearchQuery()
  };

  const onSave = () => {
    setOpenSaveSearchName(true);
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Save Seach Results' });
  };

  const handleClose = () => {
    setOpenSaveSearchName(false);
  };

  return (
    <>
      <StyledSave type='button'>
        {<SaveSearchIcon
          searchData={searchData}
          payload={payload}
          onSave={onSave}
        />}
      </StyledSave>

      {openSaveSearchName && (
        <SaveSearchName
          onClose={handleClose}
          open={openSaveSearchName}
          payload={payload}
          searchData={searchData}
          updateSearchData={updateSearchData}
        />
      )}
    </>
  );
};

export default SaveSearchButton;